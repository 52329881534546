import React from 'react'

import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { graphql } from 'gatsby';
import {GatsbyImage, getImage } from 'gatsby-plugin-image'

import Hero from '../components/Hero';
import { StaticImage } from 'gatsby-plugin-image';

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';


import Markdown from 'markdown-to-jsx';


export default function Construction({data}) {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    
    const {content} = data.file;
    const heroImage = data.hero.childImageSharp.gatsbyImageData;
    const stackedImageOne = getImage(data.back_img.childImageSharp);
    const stackedImageTwo = getImage(data.front_img.childImageSharp);

    return (
        <div>
            <Nav isActive="About"/>
            <Hero title="Construction" image={heroImage}/>

            <ParallaxProvider>
            <section className="p-6 lg:py-14 bg-sphinx-background-white-2">
              <div className="container mx-auto max-w-6xl">
                <div className=" text-2xl text-sphinx-blue font-montserrat font-medium md:text-3xl">
                  <h1 className="leading-relaxed">Two factors go hand-in-hand at SDC Construction: <br/>Honesty and Integrity.</h1>
                  <div className="w-2/6 lg:w-1/6 h-1 bg-sphinx-accent"></div>
                </div>
                
                {/* Start of row */}
                <div className="mt-4 md:mt-0 grid grid-cols-12 place-items-center gap-y-7">

                  {/* Overlapping Grid */}
                  <div className="col-span-full col-start-2 relative grid grid-cols-7 md:col-start-1 md:col-span-7">
                    <div className="col-start-1 row-start-1 col-span-4">
                      <Parallax className="custom-class" y={isMobile ? [0,0] : ["100px", "-20px"]} disabled={isMobile}>
                        <GatsbyImage
                          className="shadow-lg"
                          layout="fullWidth"
                          image={stackedImageOne}
                          loading="eager"/>
                          </Parallax>
                      </div>

                      <div className="col-start-3 row-start-1 col-span-4 z-10 mt-20">
                        <Parallax y={isMobile ? [0,0] : ["100px", "-20px"]} disabled={isMobile}>
                          <div className="md:p-6 md:border-sphinx-accent md:border-2">
                            <GatsbyImage
                            className="shadow-sm"
                            image={stackedImageTwo}
                            loading="eager"/>
                          </div>
                        </Parallax>
                      </div>
                  </div>

                  {/* Content */}
                  <div className="col-span-full md:col-span-5 md:col-start-8">
                      <div className="space-y-4 p-body text-base font-sanspro prose lg:prose-lg">
                       <Markdown>{content.sectionOneBody}</Markdown>
                      </div>
                  </div>
                </div>
              </div>
            </section>
            </ParallaxProvider>


            <section className="py-8 px-6 lg:py-14 bg-sphinx-background-white-2 bg-opacity-80">
              <div className="flex justify-center items-center">
                <div className="container mx-auto flex items-center max-w-6xl space-x-6">
                  <div className="p-body text-base font-sanspro prose lg:prose-lg">
                    <p className="text-2xl font-medium">{content.sectionTwoHeading}</p>
                    
                    <Markdown>{content.sectionTwoBody}</Markdown>
                  </div>

                  <div className="hidden lg:block">
                    <StaticImage src="../images/texas-outline-flag.svg" alt="Image of Texas with Red, White, and Blue" width={400} quality={100}/>
                  </div>
                </div>
              </div>
            </section>

            <div className="grid h-52">
              <StaticImage
                  style={{gridArea: "1/1"}}
                  layout="fullWidth"
                  aspectRatio={2/1}
                  alt="Background Image"
                  src={"../images/about/development.jpg"}
                  formats={["webp", "avif"]}
              />
              <div
                  style={{
                  gridArea: "1/1",
                  position: "relative",
                  placeItems: "center",
                  display: "grid",
                  }}
                  className="bg-sphinx-accent bg-opacity-40">
                      <div className="text-sphinx-white text-xl font-montserrat font-light lg:text-2xl flex justify-center flex-wrap">
                        <h1 className="text-4xl text-center text-sphinx-white w-full">Learn More About Our Development Projects!</h1>
                        <a href="/development" className="text-base text-center mt-5 p-2 max-w-max text-sphinx-white font-montserrat border-sphinx-white border-2 flex items-center hover:bg-sphinx-white hover:text-sphinx-accent hover:shadow-lg">Development</a>
                      </div>
              </div>
            </div>
            <Footer/>
        </div>
    )
}

export const pageQuery = graphql`
  query ConstructionPageContent {
    file(relativePath: {eq: "pages/construction.yml"}) {
      content: childPagesYaml {
        sectionOneHeading
        sectionOneBody
        sectionTwoHeading
        sectionTwoBody
      }
    }

    hero: file(relativePath: {eq: "Heros/Construction.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
      }
    }

    back_img: file(relativePath: {eq: "Properties/Alsbury Villas/alsbury-villas-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, aspectRatio: 0.8, width: 450)
      }
    }

    front_img: file(relativePath: {eq: "Properties/Fiji/fiji-villas-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, aspectRatio: 0.8, width: 450)
      }
    }
  }
`